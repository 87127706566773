
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";
import _ from "lodash";
import helpers from "@/helpers/global";
import MainCardReults from "@/components/MainCardResults.vue";
// import { ElLoading } from "element-plus";
// let loadingEl: any;

export default defineComponent({
  name: "Candidate",
  components: {
    MainCardReults,
  },
  title() {
    let name = "";
    if (this.data) {
      let newName =
        (this as any).data.firstname + " " + (this as any).data.lastname;
      if ((this as any).data.firstname && (this as any).data.lastname) {
        name = newName.trim() + " | ";
      }
    }
    return `${name}My Culture`;
  },
  watch: {
    userCurrent(index) {
      if (this.candidateList[index]) {
        const data = this.candidateList[index];
        this.focusUser.id = data.id;
        this.focusUser.name = data.name;
        this.focusUser.email = data.email;
        this.focusUser.joined = data.joined;
        this.focusUser.score = data.score;
        this.focusUser.status = data.status;
        this.focusUser.yourRating = data.yourRating;
        this.focusUser.valuesAndTraits = data.valuesAndTraits;
        this.focusUser.culturePreference = data.culturePreference;
      }
    },
    error(value) {
      console.error(value);
    },
  },
  data() {
    return {
      assessmentsName: "Culture Fit for Jan 2022 Batch",
      userCurrent: 0,
      culturePreferenceRanderChart: false,
      //*
      culturePreferenceFocus: {
        value: 82,
        title: "Overall",
        id: "Overall",
        status: "green",
        description:
          "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
        candidate: {
          clan: 12,
          create: 12,
          complete: 14,
          control: 11,
        },
        company: {
          clan: 11,
          create: 13,
          complete: 13,
          control: 11,
        },
      },
      //*/
      focusUser: {
        id: "no_user",
        name: "-",
        firstname: "-",
        lastname: "-",
        companyName: "-",
        email: "=",
        status: "Completed",
        joined: "=",
        completed: "=",
        score: "-",
        yourRating: 0,
        valuesAndTraits: {
          overall: "72%",
          values: "88%",
          valueTags: [
            {
              label: "Integrity",
              id: "Integrity",
              status: "green",
            },
            {
              label: "Entrepreneurship",
              id: "Entrepreneurship",
              status: "green",
            },
            {
              label: "Respect",
              id: "Respect",
              status: "green",
            },
            {
              label: "Honesty",
              id: "Honesty",
              status: "yellow",
            },
            {
              label: "Excellence",
              id: "Excellence",
              status: "yellow",
            },
            {
              label: "Growth",
              id: "Growth",
              status: "yellow",
            },
            {
              label: "Winning",
              id: "Winning",
              status: "red",
            },
            {
              label: "Collaboration",
              id: "Collaboration",
              status: "red",
            },
          ],
          traits: "72%",
          traitTags: [
            {
              label: "Integrity",
              id: "Integrity",
              status: "green",
            },
            {
              label: "Entrepreneurship",
              id: "Entrepreneurship",
              status: "green",
            },
            {
              label: "Respect",
              id: "Respect",
              status: "green",
            },
            {
              label: "Honesty",
              id: "Honesty",
              status: "green",
            },
            {
              label: "Excellence",
              id: "Excellence",
              status: "yellow",
            },
            {
              label: "Growth",
              id: "Growth",
              status: "yellow",
            },
            {
              label: "Winning",
              id: "Winning",
              status: "yellow",
            },
            {
              label: "Collaboration",
              id: "Collaboration",
              status: "red",
            },
          ],
        },
        culturePreference: {
          overall: "76%",
          overallStatus: "green",
          data: [
            {
              value: "82%",
              title: "Overall",
              id: "Overall",
              status: "green",
              description:
                "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "82%",
              title: "Dominant Characteristics",
              id: "Dominant Characteristics",
              status: "green",
              description:
                "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "81%",
              title: "Organizational Leadership",
              id: "Organizational Leadership",
              status: "green",
              description:
                "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "79%",
              title: "Management of Employees",
              id: "Management of Employees",
              status: "yellow",
              description:
                "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "75%",
              title: "Organization Glue",
              id: "Organization Glue",
              status: "yellow",
              description:
                "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "70%",
              title: "Strategic Emphases",
              id: "Strategic Emphases",
              status: "yellow",
              description:
                "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "59%",
              title: "Criteria of Success",
              id: "Criteria of Success",
              status: "red",
              description:
                "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
          ],
        },
        acceptableBehaviors: {
          overall: "76%",
          scenarioData: [
            {
              id: "soelja-5ae58x-a41ed",
              title: "Scenario 1",
              description: "Regularly showing up 5-min late to work",
              matchingStatus: true,
              companyValueLabel: "Frowned Upon",
              companyStatus: "green",
              userValueLabel: "Frowned Upon",
              userStatus: "green",
            },
            {
              id: "soelja-5a2e58x-a41e2d",
              title: "Scenario 2",
              description:
                "Unresponsive over chat for most of the day without an away status.",
              matchingStatus: false,
              companyValueLabel: "Frowned Upon",
              companyStatus: "green",
              userValueLabel: "Acceptable",
              userStatus: "red",
            },
            {
              id: "soelja-5ae58x-a41ed",
              title: "Scenario 1",
              description:
                "Regularly showing up 5-min late to work, It is description too longgggggggggggggggg ggggggggg woard on here and show what is going on, testset settset",
              matchingStatus: true,
              companyValueLabel: "Frowned test",
              companyStatus: "yellow",
              userValueLabel: "Frowned test",
              userStatus: "yellow",
            },
          ],
        },
      },
      candidateList: [
        {
          id: "tt123tt",
          name: "Jack Notthing",
          email: "jackdee@acme.com",
          status: "invited",
          joined: "March 22, 2022",
          completed: "",
          score: "-",
          yourRating: 0,
          valuesAndTraits: {
            overall: "-",
            values: "-",
            valueTags: [],
            traits: "-",
            traitTags: [],
          },
          culturePreference: {
            overall: "76%",
            overallStatus: "green",
            data: [
              {
                value: "82%",
                title: "Overall",
                id: "Overall",
                status: "green",
                description:
                  "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
                user: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
                company: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
              },
              {
                value: "82%",
                title: "Dominant Characteristics",
                id: "Dominant Characteristics",
                status: "green",
                description:
                  "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
                user: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
                company: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
              },
              {
                value: "81%",
                title: "Organizational Leadership",
                id: "Organizational Leadership",
                status: "green",
                description:
                  "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
                user: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
                company: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
              },
              {
                value: "79%",
                title: "Management of Employees",
                id: "Management of Employees",
                status: "yellow",
                description:
                  "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
                user: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
                company: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
              },
              {
                value: "75%",
                title: "Organization Glue",
                id: "Organization Glue",
                status: "yellow",
                description:
                  "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
                user: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
                company: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
              },
              {
                value: "70%",
                title: "Strategic Emphases",
                id: "Strategic Emphases",
                status: "yellow",
                description:
                  "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
                user: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
                company: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
              },
              {
                value: "59%",
                title: "Criteria of Success",
                id: "Criteria of Success",
                status: "red",
                description:
                  "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
                user: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
                company: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
              },
            ],
          },
        },
        {
          id: "tfsdfsdttt",
          name: "max Dee",
          email: "maxdee@acme.com",
          status: "completed",
          joined: "March 22, 2022",
          completed: "March 23, 2022",
          score: "-",
          yourRating: 0,
          valuesAndTraits: {
            overall: "72%",
            values: "88%",
            valueTags: [
              {
                label: "Integrity",
                id: "Integrity",
                status: "green",
              },
              {
                label: "Entrepreneurship",
                id: "Entrepreneurship",
                status: "green",
              },
              {
                label: "Respect",
                id: "Respect",
                status: "green",
              },
              {
                label: "Honesty",
                id: "Honesty",
                status: "yellow",
              },
              {
                label: "Excellence",
                id: "Excellence",
                status: "yellow",
              },
              {
                label: "Growth",
                id: "Growth",
                status: "yellow",
              },
              {
                label: "Winning",
                id: "Winning",
                status: "red",
              },
              {
                label: "Collaboration",
                id: "Collaboration",
                status: "red",
              },
            ],
            traits: "72%",
            traitTags: [
              {
                label: "Integrity",
                id: "Integrity",
                status: "green",
              },
              {
                label: "Entrepreneurship",
                id: "Entrepreneurship",
                status: "green",
              },
              {
                label: "Respect",
                id: "Respect",
                status: "green",
              },
              {
                label: "Honesty",
                id: "Honesty",
                status: "green",
              },
              {
                label: "Excellence",
                id: "Excellence",
                status: "yellow",
              },
              {
                label: "Growth",
                id: "Growth",
                status: "yellow",
              },
              {
                label: "Winning",
                id: "Winning",
                status: "yellow",
              },
              {
                label: "Collaboration",
                id: "Collaboration",
                status: "red",
              },
            ],
          },
          culturePreference: {
            overall: "76%",
            overallStatus: "green",
            data: [
              {
                value: "",
                title: "Overall",
                id: "Overall",
                status: "green",
                description:
                  "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
                user: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
                company: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
              },
              {
                value: "82%",
                title: "Dominant Characteristics",
                id: "Dominant Characteristics",
                status: "green",
                description:
                  "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
                user: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
                company: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
              },
              {
                value: "81%",
                title: "Organizational Leadership",
                id: "Organizational Leadership",
                status: "green",
                description:
                  "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
                user: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
                company: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
              },
              {
                value: "79%",
                title: "Management of Employees",
                id: "Management of Employees",
                status: "yellow",
                description:
                  "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
                user: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
                company: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
              },
              {
                value: "75%",
                title: "Organization Glue",
                id: "Organization Glue",
                status: "yellow",
                description:
                  "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
                user: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
                company: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
              },
              {
                value: "70%",
                title: "Strategic Emphases",
                id: "Strategic Emphases",
                status: "yellow",
                description:
                  "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
                user: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
                company: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
              },
              {
                value: "59%",
                title: "Criteria of Success",
                id: "Criteria of Success",
                status: "red",
                description:
                  "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
                user: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
                company: {
                  clan: 11,
                  create: 12,
                  complete: 14,
                  control: 12,
                },
              },
            ],
          },
        },
      ],
      culturePreferenceChartTag: "",
      // radarChartVal: {},
    };
  },
  computed: {
    error() {
      return this.$store.getters["assessment/error"];
    },
    loading() {
      return this.$store.getters["assessment/loading"];
    },
    data() {
      return this.$store.getters["assessment/data"] || {};
    },
    summaries() {
      const summaries = this.$store.getters["assessment/summaries"] || {};

      // this view want to sort values and traits by score
      const hasOwnProperty = Object.prototype.hasOwnProperty;
      if (hasOwnProperty.call(summaries, "values_and_traits")) {
        for (const i in summaries.values_and_traits.scenarios) {
          const scenario = summaries.values_and_traits.scenarios[i];
          const questions = scenario.questions;
          const name = _.snakeCase(scenario.name);
          const values = summaries.values_and_traits.candidate[name];
          questions.sort((a: any, b: any) => {
            const scoreA = values[a.id];
            const scoreB = values[b.id];
            return scoreB - scoreA;
          });
        }
      }
      return summaries;
    },
    companyColor() {
      const defaultCompanyColor = "#000000";
      const user = this.$store.getters["user/user"];
      if (user && user.color) {
        return user.color;
      }
      return defaultCompanyColor;
    },
    pagination() {
      return this.$store.getters["assessment/pagination"];
    },
  },
  async mounted() {
    this.onMounted();
  },
  methods: {
    async onMounted() {
      const valid = this.checkPropsId();
      if (valid) {
        await this.initialize();
        // this.chartculturePreference();
        // this.renderWorkStyleDoughnutChart();
      }
    },
    async initialize() {
      const u_id = String(this.$router.currentRoute.value.query.u_id);
      const _id = decodeURIComponent(atob(u_id));

      await this.$store.dispatch(
        "assessment/getPagination",
        { _id: _id },
        {
          root: true,
        }
      );

      // for graph rendering
      this.focusUser.firstname = this.data.firstname;
      this.focusUser.companyName = this.data.company_name;

      if (this.data.summaries && this.data.summaries.oaic_archetypes) {
        for (const i in this.data.summaries.oaic_archetypes.candidate_avg) {
          this.data.summaries.oaic_archetypes.candidate_avg[i] = Math.round(
            this.data.summaries.oaic_archetypes.candidate_avg[i]
          );
          this.data.summaries.oaic_archetypes.company_avg[i] = Math.round(
            this.data.summaries.oaic_archetypes.company_avg[i]
          );
        }
        //culturePreferenceFocus
        this.culturePreferenceFocus.value = Math.round(
          this.data.summaries.oaic_archetypes.overall
        );
        this.culturePreferenceFocus.candidate =
          this.data.summaries.oaic_archetypes.candidate_avg;
        this.culturePreferenceFocus.company =
          this.data.summaries.oaic_archetypes.company_avg;
      }
    },
    dateFormat(datetime: string) {
      return helpers.dateFormat(datetime);
    },
    donwloadPDF(value: any) {
      console.log("donwloadPDF", value);
    },
    checkPropsId() {
      let valid = true;
      const as_id = this.$router.currentRoute.value.query.as_id;
      const u_id = this.$router.currentRoute.value.query.u_id;
      if (as_id) {
        if (u_id) {
          this.focusUser.id = u_id + "";
        }
      } else {
        valid = false;
        ElMessage({
          message: (this as any).$t("popup.message.message_12"),
          type: "warning",
        });
        setTimeout(() => {
          this.$router.go(-1);
        }, 1000);
      }
      return valid;
    },
    // chartculturePreference() {
    //   // console.log("chartculturePreference()");
    //   // const getChart = document.getElementById("myChart");
    //   // const getChart = this.$refs.culture_preference_chart;
    //   // if (getChart && this.culturePreferenceFocus) {
    //   if (
    //     this.culturePreferenceFocus &&
    //     this.$refs.culture_preference_chart_wrapper
    //   ) {
    //     try {
    //       (
    //         this.$refs.culture_preference_chart_wrapper as any
    //       ).innerHTML = `<canvas
    //                       id="culture_preference_chart"
    //                       ref="culture_preference_chart"
    //                       width="260"
    //                       height="260"
    //                     ></canvas>`;

    //       const ctx = (document as any)
    //         .getElementById("culture_preference_chart")
    //         .getContext("2d");

    //       const options = {
    //         elements: {
    //           line: {
    //             backgroundColor: "rgba(0, 0, 0, 0)",
    //             borderWidth: 2,
    //           },
    //         },
    //         scales: {
    //           r: {
    //             // angleLines: {
    //             //   display: false,
    //             // },
    //             grid: {
    //               display: false,
    //             },
    //             min: 0,
    //             max: 100,
    //             ticks: {
    //               display: false,
    //               color: "rgba(0, 0, 0, 0)",
    //               beginAtZero: false,
    //               // max: 20,
    //               // min: 4,
    //               stepSize: 10,
    //               callback: function () {
    //                 return "";
    //               },
    //             },
    //             pointLabels: {
    //               color: "#000",
    //               fontSize: 14,
    //               font: {
    //                 size: 14,
    //                 weight: "700",
    //               },
    //             },
    //             gridLines: {
    //               lineWidth: 2,
    //               color: "lightgreen",
    //               borderDash: (context: any) =>
    //                 context.index == 6 ? [] : [6, 4],
    //             },
    //           },
    //         },
    //         plugins: {
    //           title: {
    //             display: false,
    //           },
    //           legend: {
    //             display: false,
    //           },
    //           tooltip: {
    //             enabled: false,
    //           },
    //         },
    //       };
    //       const user = [
    //         this.culturePreferenceFocus.candidate.clan,
    //         this.culturePreferenceFocus.candidate.create,
    //         this.culturePreferenceFocus.candidate.complete,
    //         this.culturePreferenceFocus.candidate.control,
    //       ];
    //       const company = [
    //         this.culturePreferenceFocus.company.clan,
    //         this.culturePreferenceFocus.company.create,
    //         this.culturePreferenceFocus.company.complete,
    //         this.culturePreferenceFocus.company.control,
    //       ];

    //       const daatsets = [];
    //       daatsets.push({
    //         label: this.focusUser.companyName,
    //         data: company,
    //         pointStyle: "line",
    //         borderColor: this.companyColor,
    //         borderDash: [3, 1],
    //       });

    //       daatsets.push({
    //         label: this.focusUser.firstname,
    //         data: user,
    //         pointStyle: "line",
    //         borderColor: "#30CCB4",
    //       });

    //       if (
    //         this.radarChartVal &&
    //         typeof (this.radarChartVal as any).destroy == "function"
    //       ) {
    //         // ctx.reset();
    //         (this.radarChartVal as any).destroy();
    //       }
    //       this.radarChartVal = new Chart(ctx, {
    //         type: "radar",
    //         data: {
    //           labels: [
    //             " ",
    //             " ",
    //             " ",
    //             " ",
    //             // "Collaborate people-oriented",
    //             // "Create dynamic & entrepreneurial",
    //             // "Complete result-oriented",
    //             // "Control process-oriented",
    //           ],
    //           // datasets: daatsets,
    //           datasets: daatsets,
    //         },
    //         options: options,
    //         // options: o,
    //       });
    //     } catch (e) {
    //       console.log(e, "error");
    //       return;
    //     }
    //   }
    // },
    // renderWorkStyleDoughnutChart() {
    //   // work_style_chart
    //   // console.log(this.data, "this.data");

    //   let chartData = [0, 0, 0];
    //   if (this.data.summaries && this.data.summaries.work_style_identifier) {
    //     const summary = this.data.summaries.work_style_identifier;
    //     chartData = [
    //       Math.round(summary.overall.product * 100),
    //       Math.round(summary.overall.process * 100),
    //       Math.round(summary.overall.people * 100),
    //     ];
    //   }

    //   const getChart = this.$refs.work_style_chart;
    //   if (getChart && this.culturePreferenceFocus) {
    //     try {
    //       const data = {
    //         labels: ["Red", "Blue", "Yellow"],
    //         datasets: [
    //           {
    //             label: "My First Dataset",
    //             // data: [60, 30, 10],
    //             data: chartData,
    //             backgroundColor: ["#FAB340", "#CC3066", "#309AE8"],
    //             hoverOffset: 4,
    //           },
    //         ],
    //       };
    //       const ctx = (getChart as any).getContext("2d");
    //       const options = {
    //         cutout: "60%",
    //         plugins: {
    //           title: {
    //             display: false,
    //           },
    //           legend: {
    //             display: false,
    //           },
    //           tooltip: {
    //             enabled: false,
    //           },
    //         },
    //       };

    //       this.radarChartVal = new Chart(ctx, {
    //         type: "doughnut",
    //         data: data,
    //         // data: {
    //         //   labels: [
    //         //     " ",
    //         //     " ",
    //         //     " ",
    //         //     " ",
    //         //     // "Collaborate people-oriented",
    //         //     // "Create dynamic & entrepreneurial",
    //         //     // "Complete result-oriented",
    //         //     // "Control process-oriented",
    //         //   ],
    //         //   // datasets: daatsets,
    //         //   datasets: daatsets,
    //         // },
    //         options: options,
    //       });
    //     } catch (e) {
    //       console.log(e, "error");
    //       return;
    //     }
    //   }
    // },
    score(value: any) {
      return Math.round(value);
    },
    scoreCls(value: any) {
      const score = this.score(value);
      let cls = "red";
      if (score >= 80) {
        cls = "green";
      } else if (score >= 60) {
        cls = "yellow";
      }
      return cls;
    },
    routeTo(routeLocationRaw: any) {
      // @todo use router and redraw ui
      // this.$router.replace(routeLocationRaw);
      const query = routeLocationRaw.query;
      const url =
        "/assessments/candidate/?as_id=" + query.as_id + "&u_id=" + query.u_id;
      window.location.href = url;
    },
    onClickRating(item: any) {
      // don't neccessary to await
      this.$store.commit("assessment/ignoreLoading", true);
      this.$store.dispatch("assessment/saveRating", item, { root: true });
      this.$store.commit("assessment/ignoreLoading", false);
    },
  },
});
